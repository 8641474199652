.left-content-new {
  margin-top: 129px;
  padding-top: 48px;
  padding-right: 20px;
  background-size: 376px 94px;
  background-repeat: no-repeat;
  padding-bottom: 100px;

  h1.ant-typography {
    color: var(--themeColor);
    font-family: Roboto;
    font-size: 48px;
    font-weight: 900;
    line-height: 56px;
    letter-spacing: 0px;
    text-align: left;
    margin: 8px 0 24px;
    @media screen and (max-width: 992px) {
      text-align: center;
    }
  }
  h4.ant-typography {
    color: var(--whiteColor);
    font-family: Roboto;
    font-size: 16px;
    font-weight: 900;
    line-height: 19px;
    letter-spacing: 0px;
    text-align: left;
  }
  h5.ant-typography {
    max-width: 530px;
    color: var(--whiteColor);
    font-family: Roboto;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
  }
  .yellow-arrow {
    position: relative;
    top: -5px;
    margin-left: 12px;
    width: 45px;
    height: 31px;
  }
  .ant-btn-primary {
    color: white;
  }

  .btn-group {
    margin-top: 80px;
    button {
      &#explore {
        padding: 14px 68.5px;
        @media screen and (max-width: 576px) {
          padding: 14px 30px;
        }
      }
      &#create {
        padding: 14px 32px;
        margin-left: 20px;
        @media screen and (max-width: 430px) {
          padding: 14px 30px;
          margin-top: 1rem;
          margin-left: 0;
        }
      }
    }
  }

  .free-to-trade {
    margin-top: 15px;
    margin-left: -20px;
  }
  .status-wrap {
    padding-top: 56px;
    .ant-typography {
      color: var(--whiteColor);
      font-family: Roboto;
      font-size: 20px;
      font-weight: 900;
      line-height: 23px;
      letter-spacing: 0px;
    }
    .mint-list {
      display: flex;
      margin-top: 40px;
      @media screen and (max-width: 992px) {
        justify-content: center;
      }
      .mint-item {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 72px;
        height: 80px;
        border: 1px solid rgb(119, 116, 255);
        border-radius: 4px;
        color: rgb(119, 116, 255);
        font-family: Roboto;
        font-size: 48px;
        font-weight: 900;
        letter-spacing: 0px;
        margin-right: 18px;
        flex-shrink: 0;
        &:last-child {
          margin-right: 0;
        }
        @media screen and (max-width: 750px) {
          width: 43.2px;
          height: 48px;
          margin-right: 12px;
          font-size: 30px;
        }
      }
    }

    .status-item-bot {
      padding-top: 52px;
      display: flex;
      .status-item {
        display: flex;
        height: 66px;
        align-items: center;
        &:first-child {
          width: 220px;
        }
        img {
          width: 44px;
          height: 44px;
          margin-right: 16px;
        }
        .right {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          font-family: Roboto;
        }
        .ant-typography {
          font-size: 14px;
          font-weight: 500;
          line-height: 16px;
          letter-spacing: 0px;
          text-align: left;
          span {
            font-size: 24px;
          }
        }
        h3.ant-typography {
          font-size: 36px;
          font-weight: 900;
          line-height: 42px;
        }
      }
    }
  }

  .status-list {
    margin-top: 42px;
    display: flex;
    @media screen and (max-width: 992px) {
      justify-content: space-between;
    }
    .status-item {
      margin-left: 120px;
      @media screen and (max-width: 992px) {
        margin-left: 0;
      }

      &:first-child {
        margin-left: 0;
      }
      div.ant-typography {
        color: var(--white56Color);
      }
    }
  }

  @media screen and (max-width: 992px) {
    padding-right: 0;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  @media screen and (max-width: 750px) {
    background-size: 300.8px 75.2px;
    padding-bottom: 30px;

    .free-to-trade {
      width: 305px;
      height: 70px;
      margin-top: 0;
    }
    .btn-group {
      margin-top: 30px;
    }
    .status-wrap {
      padding-top: 36px;
    }
  }

  @media screen and (min-width: 1400px) {
    .yellow-arrow {
      display: inline-block;
    }
  }
  @media screen and (max-width: 1400px) {
    .yellow-arrow {
      display: none;
    }
  }
  @media screen and (max-width: 992px) {
    text-align: center;
    .yellow-arrow {
      display: none;
    }
  }
  @media screen and (max-width: 500px) {
    margin-top: 30px;
    .yellow-arrow {
      display: none;
    }
  }
}
// animated background text
@keyframes background-pan {
  from {
    background-position: 0% center;
  }

  to {
    background-position: -200% center;
  }
}

.left-content-magic-text {
  animation: background-pan 3s linear infinite;
  background: linear-gradient(
    to right,
    rgb(123, 31, 162),
    rgb(103, 58, 183),
    rgb(244, 143, 177),
    rgb(123, 31, 162)
  );
  background-size: 200%;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  white-space: nowrap;
}
