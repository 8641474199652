.product-card-new {
  background: #1D1F31 !important;
  border-radius: 8px;
  overflow: hidden;
  transition: all linear 0.2s;
  
  &:hover{
    transform: scale(1.05);
    transition: all linear 0.2s;
  }

  .image-wrapper {
    width: 100%;
    position: relative;
    overflow: hidden;
    background-color: white;
    background-size: cover;
    background-position: center center;
    padding-top: 100%;

    .favorite-group {
      padding-left: 16px;
    }

    .ribon-img {
      width: 40px;
      height: 40px;
      position: absolute;
      bottom: 15px !important;
      right: 15px !important;
      background-color: white;
      border: 2px solid white !important;
      border-radius: 50%;

      & > img {
        border-radius: 50%;
      }
    }
    // .favorite-group {
    //   position: absolute;
    //   bottom: 20px !important;
    //   left: 20px;
    //   background: white;
    //   border-radius: 15px;
    //   width: 30px;
    //   padding: 5px 0;
    //   .favorite-icon {
    //     fill: #CD1A87;
    //     cursor: pointer;
    //   }
    //   .helper {
    //     color: black;
    //   }
    // }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .card-title {
    margin-top: 16px;
    margin-bottom: 6px;
    max-width: 210px;
    font-size: 20px;
    overflow: hidden;
    text-overflow: ellipsis; 
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
    line-clamp: 1; 
    -webkit-box-orient: vertical;
  }

  .price-txt{
    font-size: 12px;
    color: #999999;
    margin: 0;
    padding-top:5px;
    line-height: 1;
  }

  .bot-wrap{
    padding:0 10px 16px ;
    flex-direction: column;
    min-height: 120px !important;
    .money-wrap{
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      color: #FFFFFF;
      height: 32px;
      border: 1px solid #000000;
      border-radius: 24px;
      margin-top: 6px;
      .left{
        display: flex;
        align-items: center;
        padding: 5px 17px 5px 9px;
        height: 32px;
        background: #0F0D14;
        border-radius: 24px;
        font-size: 20px;
        color: #FFFFFF;
        min-width: 30%;
        img {
          margin-right: 4px;
        }
      }
      .right{
        flex: 1;
        padding: 0 12px;
        font-size: 14px;
        text-align: right;
        line-height: 1;
        .gray{
          color: #7B838E;
        }
        img {
          margin: -2px 4px 0 6px;
        }
      }
    }
  }

  /* type2 contents */
  .description {
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    display: -webkit-box;
   -webkit-line-clamp: 2; /* number of lines to show */
           line-clamp: 2; 
   -webkit-box-orient: vertical;
  }
}

.form-group {
  border: none;
  border-radius: 0;
}

// small card UI
.ant-col-xxl-3{
  .card-title{
    margin-top: 10px;
  }
  .bot-wrap{
    padding: 0 10px 10px;
  }
  .money-wrap{
    height: 20px !important;
  }
  .product-card-new .money-wrap{
    .left{
      width: 100%;
      font-size: 14px;
      height: 20px;
      padding: 3px 17px 3px 5px;
      img {
        width: 10px !important;
        height: 10px;
      }
    }
    .right{
      text-align: left;
      padding: 7px 0 0 0;
      font-size: 12px;
      width: 100%;
      line-height: 1;
      img {
        margin: -1px 4px 0 6px;
      }
    }
  }
}