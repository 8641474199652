#create-collection-new {
  width: 640px;
  margin: 0 auto;
  padding-top: 20px;
  padding-bottom: 100px;

  @media screen and (max-width: 750px) {
    width: auto;
    .theme-block-img {
      display: none;
    }
    .form-group,
    .upload-group {
      padding: 0 31px 0 27px !important;
    }
    .form-action {
      width: 100% !important;
      text-align: center;
      button {
        width: 100% !important;
      }
    }
  }

  .create-collect-content {
    text-align: center;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #ffffff;
    background: #161822;

    .theme-block {
      position: relative;
      height: 50px;
      background-color: var(--themeColor);
      margin-bottom: 6px;

      .theme-block-button-group {
        position: absolute;
        top: 175px;
        right: -165px;
        transform: rotate(90deg);

        .theme-block-button-badge {
          font-size: 14px;
          color: #0f0d14;
          background-color: #ffe483;
          border: 1px solid black;
          border-radius: 5px;
          padding: 3px;
          margin-right: 20px;
        }

        .theme-block-button {
          border-radius: 0;
          border: 1px solid #7774ff;
          color: #ffffff;
        }
        .theme-block-button-focus {
          background-color: #7774ff;

          &::after {
            content: "";
            position: absolute;
            top: 100%;
            left: 50%;
            margin-left: -10px;
            border-width: 10px;
            border-style: solid;
            border-color: #7774ff transparent transparent transparent;
          }
        }
      }

      .theme-block-img {
        position: absolute;
        bottom: -1px;
        right: 28px;
        width: 295px;
        height: 127px;
        background-size: cover;
        background-repeat: no-repeat;
      }
      .top-title {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        font-family: Roboto;
        height: 70px;
        font-size: 20px;
        font-weight: 900;
        line-height: 28px;
        letter-spacing: 0px;
        padding: 10px 20px 0;
      }
      .to-free-collection {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 32px;
        width: 150px !important;
        padding: 5px 15px;
        border-radius: 16px;
        overflow: hidden;
        background: #7774ff;
        color: #fff;
        font-size: 14px;
      }
    }

    p {
      margin-bottom: 0;
    }
    h4.ant-typography-secondary {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 900;
      font-size: 18px;
      line-height: 21px;
      color: var(--themeColor) !important;
      padding-bottom: 4px;
      margin-bottom: 0 !important;
      margin-top: 0 !important;
      &.white {
        color: #fff !important;
        padding-bottom: 8px;
      }
    }

    div.ant-typography-secondary {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: #ffffff !important;
      margin-bottom: 0 !important;
      margin-top: 0 !important;
    }

    .file-uploader {
      margin-top: 16px;
      border: none;
    }

    .form-group {
      background: #161822;
      text-align: left;
      padding: 8px 40px 0 40px;
      border-radius: 0 0 6px 6px;
      max-width: 980px;
      margin: 0s auto 0;
      .form-item {
        & > .ant-typography {
          margin-bottom: 10px;
          margin-top: -10px;
        }
      }

      .form-action {
        margin-top: 3px;
        position: relative;
        top: 26px;

        button {
          height: 56px;
          width: 320px;
          background-color: #7774ff;
          border-radius: 28px;
          border: none;
        }
      }

      .form-item {
        margin-top: 14px;
        width: 100%;
        input {
          color: #fff;
          background: #0f0d14;
          border-radius: 4px;
          border: none;
        }
      }
    }

    .ant-select .ant-select-selector {
      color: #fff;
    }
  }

  #logo-uploader {
    width: 150px;
    height: 150px;
    background: #0f0d14;
    border-radius: 4px;
    padding: 0;
    border: none;
    img {
      border-radius: 4px;
    }
  }
  #featured-uploader {
    width: 280px;
    height: 188px;
    background: #0f0d14;
    border-radius: 4px;
    @media screen and (max-width: 450px) {
      width: 100%;
      height: 150px;
    }
  }
  #banner-uploader {
    width: 560px;
    height: 180px;
    background: #0f0d14;
    border-radius: 4px;
    @media screen and (max-width: 768px) {
      width: 100%;
      height: 150px;
    }
  }
}
