.reward {
    .reward-header {
        padding: 80px;
        text-align: center;
        background-color: var(--bgSecondaryColor);
        border-bottom: 1px solid var(--white40Color);
    }

    .reward-content {
        margin-top: 100px;

        .reward-list {
            margin-top: 50px;
            // background-color: var(--bgSecondaryColor);

            // background-color: white;

        }
        
    }
}