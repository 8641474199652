.coming-soon-model {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;

  .coming-soon {
    position: fixed;
    display: flex;
    justify-content: center;
    width: 120%;
    height: 110px;
    transform: rotate(-11deg);
    top: 35%;
    left: -10%;
    z-index: 1;
    background-color: rgba(46, 55, 62, .8);
    font-size: 62px;
    color: #a2e6b7;
    text-shadow: 0 0 10px #19e74e;
    font-family: 'rigel star';
    font-weight: bold;
    line-height: 121px;
    letter-spacing: 7px;
  }

  @media screen and (max-width: 750px) {
    .coming-soon {
      font-size: 26px;
    }
  }

}