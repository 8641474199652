.merchant-wrapper {
  margin: 3rem 0;

  border: 1px solid rgb(83, 242, 212);
  box-shadow: 0px 0px 70px rgb(89 86 229 / 61.8%);
  border-radius: 2rem;
  overflow: hidden;
  padding: 2rem;
  background: rgba(29, 31, 49, 1);
  @media screen and (max-width: 768px) {
    padding: 1rem;
  }
  .merchant-content-left {
    width: 100%;
    height: 600px;

    @media screen and (max-width: 768px) {
      height: 400px;
    }
  }
  .merchant-content-right {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    .merchant-content-right-title {
      text-align: center;
      font-size: 4rem;
      text-transform: uppercase;

      @media screen and (max-width: 768px) {
        margin-top: 1rem;
        font-size: 3rem;
      }
    }

    .merchant-content-right-label {
      font-weight: 200;
    }
  }
}
.merchant-model {
  width: 100%;
  height: 100%;
  background: #3e3e3e50;
  border: 1px solid rgb(83, 242, 212);
  border-radius: 2rem;
}

// magic text
@keyframes background-pan {
  from {
    background-position: 0% center;
  }

  to {
    background-position: -200% center;
  }
}

.merchant-title-magic-text {
  animation: background-pan 3s linear infinite;
  background: linear-gradient(
    to right,
    rgb(123, 31, 162),
    rgb(103, 58, 183),
    rgb(244, 143, 177),
    rgb(123, 31, 162)
  );
  background-size: 200%;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  white-space: nowrap;
}

.merchant-section-margin {
  margin-top: 1rem;
}

// .merchant-select-label {
//   display: flex;
//   justify-content: flex-start;
//   align-items: center;
// }
