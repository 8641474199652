.form-group {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #161822;
  .form-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    .stake-option {
      color: white;
      padding: 0px 6px;
      border-radius: 15px;
      margin-left: 4px;
      background-color: #7774ff;
    }
  }
  .form-item {
    width: 100%;
    margin-top: 35px;
    margin-right: 20px;
    margin-left: 20px;
    border-radius: 10px;
    background-color: #161822;
    // background-color: #372f47;
    color: white;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    .stake-header {
      display: flex;
      justify-content: space-between;
      margin-top: 10px;
      margin-left: 10px;
      margin-right: 10px;
      font-family: "Roboto";
      font-style: normal;
    }
    .stake-content {
      display: flex;
      justify-content: space-between;
      margin-top: 10px;
      margin-left: 10px;
      margin-right: 10px;
      align-items: center;
      .ant-input {
        height: 20px;
        min-width: 150px;
        width: 95%;
        background-color: #372f47;
      }
    }
    .percentage-btn-group {
      display: flex;
      float: right;
      margin-top: 10px;
      margin-right: 10px;
      margin-bottom: 10px;
      .percentage-button {
        padding: 1px 9px;
        border-color: #53f2d4;
        border-radius: 19px;
        border: 2px solid;
        color: #53f2d4;
        font-size: 13px;
        cursor: pointer;
        margin-left: 5px;
      }
    }
  }
  .bottom-btn-group {
    display: flex;
    margin-top: 20px;
    justify-content: space-around;
    width: 100%;
  }
}
.ant-layout-content .staking-page {
  position: relative;
  padding-top: 44px;
  z-index: 20;
  background: radial-gradient(
      at center top,
      rgb(12, 65, 115),
      rgba(12, 53, 115, 0) 45%
    ),
    radial-gradient(
      at center bottom,
      rgb(69, 25, 124),
      rgba(69, 25, 124, 0) 45%
    );
  .earn-top-content {
    position: relative;
    padding-bottom: 80px;
    .top-right-img {
      position: absolute;
      top: -36px;
      right: 100px;
      width: 476px;
      height: 373px;
    }
  }

  .percentage {
    font-family: "GulfsDisplay-ExtraExpanded";
    font-style: normal;
    font-weight: 800;
    font-size: 30px;
    line-height: 44px;
    color: #53f2d4;
    padding-bottom: 20px;
    .value {
      font-family: "rigel star";
      // font-family: 'GulfsDisplay-ExtraExpanded';
      font-style: normal;
      font-weight: 800;
      font-size: 56px;
      line-height: 81px;
      text-align: center;
      color: #53f2d4;
      padding-right: 20px;
    }
    img {
      margin-left: 10px;
    }
  }

  .ant-tabs-tab {
    width: 182px;
    height: 28px;
    padding: 2px 17px;
    background: #1d1f31;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    &.ant-tabs-tab-active {
      background: var(--themeColor);
      .ant-tabs-tab-btn {
        color: var(--bgMainColor);
      }
    }
    &:nth-child(1) {
      border-radius: 14px 0 0 14px;
    }
    &:nth-child(2) {
      border-radius: 0 14px 14px 0;
      margin-left: 0;
    }
  }

  .tab-content {
    background-size: 100% 16px;
    background-repeat: no-repeat;
    background-position: top center;
    padding-top: 16px;
    background-color: #1d1f31;
    border-radius: 8px;
    padding: 44px 18px;
    .wrapper-new {
      background: #0f0d14;
    }
  }
  .ant-tabs-nav .ant-tabs-nav-wrap {
    border-bottom: none;
  }

  .tab-card {
    // background: rgba(29, 31, 49, 1);

    background: linear-gradient(
      rgba(119, 214, 255, 0.08),
      rgba(202, 169, 255, 0.08)
    );
    // background: #1d2331;
    border-radius: 1rem;
    color: #fff;
    padding: 2rem;
    border: 1px solid rgba(255, 255, 255, 0.16);
    // border: 1px solid rgb(83, 242, 212);
    // border: 1px solid rgb(119, 116, 255);
    // box-shadow: 0px 0px 60px rgb(89 86 229 / 61.8%);

    .card-header-divider {
      background: #394055;
      margin: 1rem 0 1.5rem;
    }

    .card-header {
      display: flex;
      justify-content: center;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 900;
      font-size: 1.5rem;
      // line-height: 19px;
      color: #ffffff;
      // padding: 12px 26px;
      text-align: center;
      // border-bottom: 1px solid #394055;

      .sub-txt {
        position: relative;
        top: 4px;
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        // line-height: 14px;
        color: #ffffff;
      }
    }
    .card-content {
      // padding: 1rem;
      // padding: 32px 0 22px;
      text-align: center;
      .card-content-background {
        width: 100%;
        background: #00000055;
        border-radius: 1rem;
        .content-data {
          display: inline-block;
          margin: auto;
        }
      }

      .txt {
        padding: 0.75rem;
        text-align: left;
        // padding: 8px 0 25px;
        font-family: "Roboto";
        font-style: normal;
        font-weight: 900;
        font-size: 1rem;
        // line-height: 19px;
        color: #ffffff;
        .sub-txt {
          position: relative;
          font-family: "Roboto";
          font-style: normal;
          font-weight: 400;
          font-size: 1.5rem;
          // line-height: 14px;
          color: #a77ae2;

          .nicho-txt {
            color: grey;
            font-size: 1rem;
          }
        }
      }
      .connect-wallet {
        width: 80%;
        margin: 0 auto;
        border: 1px solid #7774ff;
        border-radius: 18px;
        height: 36px;
        padding: 4px 24px;
        color: #7774ff;
      }

      .margin-btn {
        margin-bottom: 30px;
      }
    }
  }

  .staking-rewards {
    display: flex;
    position: relative;
    padding: 37px 30px 20px 176px;
    height: 216px;
    box-sizing: border-box;
    h6 {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 900;
      font-size: 16px;
      line-height: 19px;
      color: #ffffff;
      padding-bottom: 20px;
      margin: 0;
    }
    p {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: #ffffff;
      padding-bottom: 12px;
      margin: 0;
    }
    a {
      color: var(--themeColor);
    }

    .graph-img {
      position: absolute;
      left: 45px;
      bottom: 0;
    }
  }
  .staking-left {
    width: 100%;
  }
  // .staking-right{
  //   padding-left: 10%;
  //   .box {
  //     display: flex;
  //     align-items: center;
  //     padding-bottom: 14px;
  //     img{
  //       width: 29px;
  //       height: 29px;
  //       margin-right: 12px;
  //     }
  //     p{
  //       padding-bottom: 3px;
  //       &:last-child{
  //         padding-bottom: 0;
  //       }
  //     }
  //   }
  //   .box2{
  //     p{
  //       padding-bottom: 8px;
  //     }
  //   }
  //   .light-txt{
  //     font-family: 'Roboto';
  //     font-style: normal;
  //     font-weight: 900;
  //     font-size: 14px;
  //     line-height: 16px;
  //     color: var(--themeColor);
  //   }
  // }
  .tab-card2 {
    .card-content {
      padding-top: 27px;
    }
    .inner-card {
      text-align: left;
      padding: 0 30px 0 26px;
      p {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;

        color: #ffffff;
      }
      h6 {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 900;
        font-size: 16px;
        line-height: 19px;
        /* identical to box height */

        color: #ffffff;
      }
    }
  }

  .top-user-table {
    margin-top: 30px;
    .table-title {
      padding: 28px 17px 17px;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 900;
      font-size: 16px;
      line-height: 19px;
      color: #ffffff;
    }
  }

  .earn-bot-footer {
    text-align: center;
    max-width: 900px;
    margin: 0 auto;
    padding-top: 107px;
    p {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 28px;
      text-align: center;
      color: #ffffff;
      padding-bottom: 13px;
      margin: 0;
    }
    h5 {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 700;
      font-size: 30px;
      line-height: 35px;
      text-align: center;
      color: var(--themeColor);
      padding-bottom: 23px;
      margin: 0;
    }
    img {
      margin-bottom: 28px;
    }
  }
  .fine-desc {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #ffffff;
    max-width: 900px;
    margin: 0 auto;
    padding-bottom: 23px;
    p {
      font-size: 14px !important;
      padding-bottom: 0 !important;
      line-height: 1.3 !important;
    }
  }
  h2 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 35px;
    text-align: center;

    color: #ffffff;
  }

  .program-content {
    width: 100%;
    height: 113px;
    background-size: cover;
    padding: 40px 0 0 0;
    margin-bottom: 10px;
    box-sizing: border-box;
    .ant-row {
      width: 65%;
      margin: 0 auto;
      text-align: center;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: #ffffff;
      p {
        padding-bottom: 8px;
        margin: 0;
      }
    }
    .program-value {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 900;
      font-size: 20px;
      line-height: 23px;

      color: var(--themeColor);
    }
  }

  @media screen and (max-width: 750px) {
    width: 100%;
    box-sizing: border-box;
    overflow-x: hidden;
    .top-right-img {
      display: none;
    }
    .percentage .value {
      font-size: 38px;
    }
    .connect-wallet {
      padding: 4px 8px !important;
    }
    .staking-rewards {
      padding: 30px 20px 20px 76px !important;
      min-height: 216px;
      height: auto !important;
    }
    .graph-img {
      left: 10px !important;
    }
    .staking-right {
      display: none;
    }
  }

  @media screen and (max-width: 1100px) {
    .top-right-img {
      position: absolute;
      right: 0px !important;
    }
  }
}
