.multiple-slider-comp {
  padding-top: 66px;
  padding-bottom: 6px;
  text-align: center;


  @media screen and (max-width: 992px) {
    display: none;
  }
  .collection-list{
    position: relative;
    display: flex;
    justify-content: center;
    height: 484px;

    @media screen and (max-width: 1200px) {
      transform: scale(0.8);
    }

    @media screen and (max-width: 992px) {
      transform: scale(0.6);
    }

  }
  .ant-image-img{
    border-radius: 4px;
  }


  .prev, .next {
    transform: scale(0.75) !important;
    position: relative;
    z-index: 1;
    border-color: transparent !important;
    cursor: pointer;
    &:after{
      position: absolute;
      content: '';
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(15, 13, 20, 0.6);
      z-index: 2;
    }
  }

  .prev2, .next2 {
    position: relative;
    transform: scale(0.5);
    border-color: transparent;
    border-color: transparent !important;
    cursor: pointer;
    &:after{
      position: absolute;
      content: '';
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(15, 13, 20, 0.8);
      z-index: 1;
    }
  }

  .prev{
    left: 178px;
    top: 22px;
    transition: all linear 0.2s;
  }
  .prev2{ 
    left: 22px;
    top: -85px;
    transition: all linear 0.2s;
  }
  .next{
    left: 700px;
    top: 22px;
    transition: all linear 0.15s;

  }
  .next2{
    top: -85px;
    left: 836px;
    transition: all linear 0.15s;
  }
  .active{
    position: relative;
    z-index: 2;
    left: 420px;
    top: 41px;
    transform: scale(1) !important;
    transition: all linear 0.2s;
    box-shadow: 0px 2px 69px rgba(89, 86, 229, 0.6);
  }

  .left-arrow,.right-arrow{
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 100px;
    width: 56px;
    height: 56.12px;
    box-sizing: border-box;
    border: 1px solid rgb(83, 242, 212);
    box-shadow: 0px 10px 10px rgba(77, 78, 86, 0.04);
    border-radius: 100%;
    cursor: pointer;
    &:after{
      position: relative;
      left: 2.5px;
      content: '';
      width: 15px;
      height: 15px;
      border-top: 1px solid rgb(83, 242, 212);
      border-right: 1px solid rgb(83, 242, 212);
    }
    &:hover{
      background: rgb(83, 242, 212);
      &:after{
        border-color: rgb(15, 13, 20);
      }
    }
  }

  .left-arrow{
    left: 0;

    &:after{
      content: '';
      transform: rotate(-135deg);
    }
  }

  .right-arrow{
    right: 0;

    &:after{
      position: relative;
      left: -2.5px;
      content: '';
      transform: rotate(45deg);
    }
  }

  .collection-item{
    position: absolute;
    padding: 30px 15px;
    width: 440px !important;
    height: 400px;
    background: rgb(22, 24, 34);
    border: 1px solid rgb(119, 116, 255);
    border-radius: 4px;
    box-sizing: border-box;
    flex-shrink: 0;
    transform: scale(0.55);

    .collection-item-top{
      display: flex;
      align-items: center;
      text-align: left;
      height: 51px;
      margin-bottom: 30px;

      .ant-image{
        border-radius: 100%;
        overflow: hidden;
        margin-right: 13px;
      }
      .description{
        font-size: 10px;
        color: #999;
        width: 346px;
      }
    }
    .title{
      font-weight: 700;
      font-family: 'Roboto';
      font-size: 32px;
    }

    .collection-item-bot{
      display: flex;
      .ant-image{
        flex-shrink: 0;
      }
    }

    .collection-title{
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-size: 14px;
      width: 100%;
    }

    .collection-item-right{
      display: flex;
      flex-wrap: wrap;
      margin-left: 10px;
      .ant-image:first-child{
        margin-bottom: 10px;
      }
    }


  }

}


.carousel-wrap .slick-dots{
  margin-left: 0 !important;
}