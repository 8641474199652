.collection-card-new {
    background: transparent;
    border-radius: 8px;
    padding: 0px;
    text-align: left;
    transition: all linear 0.2s;

    &:hover{
      transform: scale(1.05);
      transition: all linear 0.2s;
    }
  
    .collection-img-wrap {
      position: relative;
      width: 92%;
      padding-top: 60%;
      position: relative;
      background-color: transparent;
      background-size: cover;
      background-position: center center;
      margin: 0 auto;

      .collection-img{
        position: absolute;
        left: 50%;
        transform: translate(-50%);
        top: 0;
        width: 100%;
        height: 100%;
        border-radius: 8px 8px 0px 0px;
        overflow: hidden;

        &.collection-img1{
          width: 100%;
          top: -0;
          z-index: 4;
        }
        &.collection-img2{
          width: 85%;
          // top: -16px;
          top: -8px;
          z-index: 3;
          filter: blur(1px);
          &:after{
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0,0,0,0.5);
            z-index: 1;
          }
        }
        &.collection-img3{
          width: 65%;
          // top: -26px;
          top: -13px;
          z-index: 2;
          filter: blur(2px);
          &:after{
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0,0,0,0.5);
            z-index: 1;
          }
        }
      }
    
    }

    .bot-wrap{
      padding: 0 16px 20px 20px;
      color: #fff;
      background: #272A40;
      border-radius: 8px;
      height: 102px;
    }
  
    .title {
      margin: 0 20px 6px 0;
      padding-top: 21px;
      text-align: left;
      font-size: 16px;
    }
    .creater {
      margin: 10px;
      text-align: center;
      .title {
        font-size: 16px;
        color: white;
      }
    }


    .description {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
              line-clamp: 2; 
      -webkit-box-orient: vertical;
      text-align: left;
      font-size: 12px;
      color: #9EA3BA !important;
    }
    .favorite {
      position: absolute;
      top: 240px;
      left: 50px;
      background: white;
      border-radius: 15px;
      width: 30px;
      padding: 5px 0;

      .heart-icon {
        fill: #CD1A87;
      }
      .heart-icon-unactivate {
        fill: #777;
      }
    }
    .owner-img {
      background-color: white;
      border: 2px solid white;
      border-radius: 50%;
      position: absolute;
      bottom: 0px;
      left: 8px;
      width: 30px;
      height: 30px;
      transform: translateY(50%);
      overflow: hidden;
      z-index: 5;
      .ant-image {
        width: 100%;
        height: 100%;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }