.park-page {
  padding-top: 98px;
  text-align: center;

  img {
    max-width: 100%;
  }

  .top-img {
    width: 1206px;
    border-radius: 48px;
    margin: 42px 0;
  }

  h2.ant-typography {
    padding-bottom: 15px;
  }

  .game-item {
    display: flex;
    width: 1200px;
    min-height: 264px;
    height: auto;
    padding: 32px;
    box-sizing: border-box;
    margin-bottom: 24px;

    background: #161822;
    border-radius: 48px;

    .game-left {
      width: 200px;
      border-radius: 32px;
    }

    .game-right {
      text-align: left;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-left: 32px;
    }

    .bot-wrap {
      text-align: right;
      padding-top: 30px;
    }

    .theme-button {
      color: #fff;
    }
  }

  @media screen and (max-width: 750px) {
    background-size: 100% 300px !important;

    .park-txt-img {
      width: 200px !important;
    }

    .game-item {
      flex-wrap: wrap;
      height: auto;
      width: auto !important;
      padding: 22px !important;
      margin-bottom: 40px !important;

      .game-left {
        width: 200px;
        margin: 0 auto;
      }

      .game-right {
        padding-top: 20px;
        padding-left: 0;
        width: 100%;

        h3 {
          font-size: 26px !important;
        }

        div.ant-typography {
          font-size: 14px !important;
        }
      }

      .bot-wrap {
        text-align: center;
      }
    }

  }
}