@mixin displayBackground {
  width: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid black;
  border-radius: 1rem;
  background: black;
  box-shadow: 0px 0px 1px white;
}

.web2wallet-divider {
  // background: grey;
  // box-shadow: 0px 2px 69px rgb(89 86 229 / 60%);
  // border: 1px solid rgb(119, 116, 255);
  margin: 0.5rem 0;
}

.web2wallet-button {
  border-radius: 1rem;
}

.web2wallet-wrapper {
  background: black;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-popover-placement-bottom {
  position: fixed;
}
.web2-wallet-popover{
  .ant-popover-inner {
    min-width: 400px;
    height: 575px;
    overflow: scroll;
    border: 1px solid rgb(119, 116, 255);
    border-radius: 1rem;
    padding: 0.5rem;
    background: #1a1919;
    box-shadow: 0px 2px 69px rgb(89 86 229 / 60%);
    //   overflow: hidden;
  
    .web2wallet-content {
      // min-width: 400px;
      // height: 585px;
      width: 100%;
      // overflow: scroll;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
  
      .web2wallet-title-text {
        margin-bottom: 0.5rem;
        font-size: 2rem;
      }
  
      .web2wallet-balance {
        @include displayBackground();
  
        .web2wallet-balance-fiat {
          color: grey;
          margin-bottom: 0.5rem;
          font-size: 1rem;
          // margin-top: -0.5rem;
        }
  
        .web2wallet-balance-button-group {
          // width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          gap: 15px;
        }
      }
  
      .web2wallet-transfer {
        @include displayBackground();
  
        .web2wallet-content-input {
          border-radius: 1rem;
          padding: 0.5rem 1rem;
          margin-bottom: 1rem;
          background: #1a1919;
          color: white;
  
          input {
            background: #1a1919;
            color: white;
          }
        }
      }
  
      .web2wallet-activity {
        @include displayBackground();
  
        .web2wallet-activity-button {
          margin-top: 1rem;
          border-radius: 1rem;
        }
      }
    }
  }
}


