.recommended-collections {
  margin-top: 210px;
  @media screen and (max-width: 992px) {
    margin-top: 110px;
  }
  
  // h2 {
  //   margin-bottom: 50px;
  // }
  button {
    margin: 10px 0;
    background: transparent;
    color: white;
    height: 40px;
    width: 150px;
    border-radius: 10px;
  }
  button:hover {
    background: transparent;
  }
}