.product-list {
  margin-top: 100px;

  @media screen and (max-width: 992px) {
    margin-top: 70px;
  }  
  h2 {
    margin-bottom: 50px;
  }
  div.load-more {
    text-align: center;
    margin-top: 84px;
    margin-bottom: -10px;
    & > button {
      padding: 14px 38px;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
    }
  }
}
.profile-content{
  .set-column-wrap{
    top: 0;
  }
}