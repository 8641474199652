.payment-modal-container {
  background: #000000;
  color: white;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .payment-modal-title {
    text-align: center;
    font-size: 2rem;
    font-weight: 900;
    margin-bottom: 1rem;
  }
  .payment-modal-price {
    font-size: 1.5rem;
    font-weight: 900;
    color: green;
  }
  .payment-modal-currency {
    font-size: 1rem;
    font-weight: 300;
    color: gold;
  }

  .payment-modal-paypal-button {
    width: 250px;
  }
}
