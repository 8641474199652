.main-content {
  margin: 0 auto !important;
  max-width: 1440px;
}
.Introduction{
  padding-bottom: 60px;
  @media screen and (max-width: 750px) {
    padding-bottom: 5px;
  }
  .center-page{
    position: relative;
    z-index: 1;
  }
  .war3-homepage-video{
    position:absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 48px;
    min-width: 100%;
    opacity: 0.7;
    object-fit: cover;
  }
}

