.coming-back-model {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;

  .coming-back {
    position: fixed;
    display: flex;
    justify-content: center;
    padding: 0 8rem;
    width: 120%;
    // height: 240px;
    // transform: rotate(-11deg);
    top: 40%;
    left: -10%;
    z-index: 1;
    background-color: rgba(46, 55, 62, 0.8);
    font-size: 32px;
    color: #a2e6b7;
    text-shadow: 0 0 10px #19e74e;
    font-family: "rigel star";
    font-weight: bold;
    line-height: 121px;
    letter-spacing: 7px;
    text-align: center;
  }

  @media screen and (max-width: 750px) {
    .coming-back {
      font-size: 26px;
      padding: 0 3.5rem;
      top: 5%;
    }
  }
}
