.nft-balance-header-new {
  .bg-content {
    width: 100%;
    height: 343px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    @media screen and (max-width: 991px) {
      height: 200px;
    }
  }

  .personal-content {
    min-height: 129px;
    padding: 13px 32px 12px;
    box-sizing: border-box;

    .user-info {
      display: flex;
      align-items: center;

      h3 {
        font-weight: bold;
        font-size: 32px;
        line-height: 140%;
      }

      h4 {
        border-left: 1px solid var(--white40Color);
        padding-left: 17px;
        margin-left: 8px;
      }

    }

    .bio-wrap {
      max-width: 800px;
      font-size: 14px;
    }

    .user-avatar {
      position: relative;
      border-radius: 50%;
      width: 80px;
      height: 80px;
      background-color: var(--themeColor);
      margin-right: 16px;
      flex-shrink: 0;

      &>img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
      }


      .follow-btn {
        position: absolute;
        left: 50%;
        bottom: -10px;
        transform: translateX(-50%);
        display: flex;
        justify-content: center;
        align-items: center;
        width: 70px;
        height: 22px;
        font-size: 12px;
        color: #000;
        background-color: var(--themeColor);
        border-radius: 14px;
        border: none;
        font-weight: 500;
        line-height: 24px;
        padding: 3px 6px;

        span {
          display: flex;
          align-items: center;

          svg {
            margin-left: 8px;
          }
        }
      }
    }

    .user-address {
      // margin-left: 6px;
      font-size: 14px;
      line-height: 16px;
      color: #8B97B2;
    }

    .mail-icon {
      margin-right: 20px;
    }

    .twitter-icon,
    .mail-icon {
      &:hover {
        path {
          fill: #fff;
        }
      }

      path {
        fill: #7774FF;
      }
    }
  }

}